<template>

  <div>
    <!-- Filters -->
    <recurring-list-filters
      @search="search"
    />

    <!-- Table Container Card -->
    <b-card no-body>

      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ data.item.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(order_amount)="data">
          {{ data.item.order_currency }} {{ data.item.order_amount }}
        </template>

        <template #cell(trial_amount)="data">
          {{ data.item.order_currency }} {{ data.item.trial_amount }}
        </template>

        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>

        <template #cell(prev_exec_time)="data">
          {{ convertUnixTime(data.item.prev_exec_time) }}
        </template>

        <template #cell(next_exec_time)="data">
          {{ convertUnixTime(data.item.next_exec_time) }}
        </template>

        <template #cell(recurring_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.recurring_status)}`"
          >
            {{ data.item.recurring_status }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BBadge, BCard, BRow, BCol, BTable, BPagination, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import RecurringListFilters from './RecurringListFilters.vue'
import useRecurringJs from './recurring'

const {
  searchRecurringOrders,
} = useRecurringJs()

export default {
  components: {
    RecurringListFilters,

    BBadge,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      searchRequest: {},
      transactions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      transactionsTableFields: [
        'id',
        'merchant',
        'app',
        { key: 'out_trade_no', label: 'MERCHANT REFERENCE' },
        { key: 'create_time', label: 'ORDER TIME' },
        { key: 'order_amount', label: 'ORDER AMOUNT' },
        { key: 'trial_period', label: 'TRIAL PERIOD' },
        { key: 'trial_amount', label: 'TRIAL AMOUNT' },
        { key: 'method', label: 'METHOD' },
        { key: 'recurring_status', label: 'STATUS' },
        { key: 'recurring_interval', label: 'INTERVAL' },
        { key: 'recurring_quantity', label: 'QUANTITY' },
        { key: 'current_stage', label: 'CURRENT STAGE' },
        { key: 'prev_exec_time', label: 'PREVIOUS' },
        { key: 'next_exec_time', label: 'NEXT' },
      ],
      currentPage: 1,
      totalTransactions: 0,
      tzSelected: 'Etc/GMT+3',
      loading: false,
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  methods: {
    resolveStatusVariant(status) {
      switch (status) {
        case 'Active':
          return 'primary'
        case 'Charging':
          return 'success'
        case 'Refused':
        case 'Closed':
        case 'Cancelled':
          return 'warning'
        case 'Initial':
          return 'info'
        default:
          return 'secondary'
      }
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    convertUnixTime(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.unix(value).utc(), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    search(params) {
      this.loading = true
      this.transactions = []
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      searchRecurringOrders(
        this.searchRequest,
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalTransactions
          this.dataMeta.of = this.totalTransactions

          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `Fetch recurring order list with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
