import store from '@/store'
import router from '@/router'
import merchantStoreModule from '@/pagsmile/merchantStoreModule'
import transactionStoreModule from '@/pagsmile/transactionStoreModule'

export default function transactionsJs() {
  const MERCHANT_STORE_MODULE_NAME = 'merchant'
  const TRANSACTION_STORE_MODULE_NAME = 'transaction'

  // Register module
  if (!store.hasModule(MERCHANT_STORE_MODULE_NAME)) store.registerModule(MERCHANT_STORE_MODULE_NAME, merchantStoreModule)
  if (!store.hasModule(TRANSACTION_STORE_MODULE_NAME)) store.registerModule(TRANSACTION_STORE_MODULE_NAME, transactionStoreModule)

  const fetchMerchants = (vm, callback) => {
    store
      .dispatch('merchant/fetchMerchants')
      .then(response => {
        const { code, data } = response.data

        const merchants = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const merchant = { label: item.merchant_name, value: item.merchant_no }
            merchants.push(merchant)
          })
        }

        callback(merchants)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchants', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchMerchantApps = (vm, merchantNo, callback) => {
    store
      .dispatch('merchant/fetchMerchantApps', merchantNo)
      .then(response => {
        const { code, data } = response.data

        const apps = [{ label: 'All', value: '' }]
        if (code === '10000') {
          data.forEach(item => {
            const app = { label: item.app_name, value: item.app_id }
            apps.push(app)
          })
        }
        callback(apps)
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Merchant APPs', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchChannelFilters = (vm, callback) => {
    store
      .dispatch('merchant/fetchChannelFilters')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channels', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchPayChannelListDetail = (vm, callback) => {
    store
      .dispatch('merchant/fetchPayChannelListDetail')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Channel List Detail', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const searchRecurringOrders = (params, success, fail) => {
    store
      .dispatch('transaction/fetchRecurringOrders', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => {
        fail('error')
      })
  }

  const downloadTransactions = (vm, params) => {
    store
      .dispatch('merchant/downloadTransactions', params)
      .then(response => {
        const { code } = response.data

        if (code === '10000') {
          vm.$bvToast.toast('Download Transactions Request Submitted', {
            title: 'Success',
            icon: 'AlertTriangleIcon',
            variant: 'success',
            toaster: 'b-toaster-top-right',
            solid: true,
          })

          setTimeout(() => {
            router.push({
              name: 'transactions-download-log',
            })
          }, 1000)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error Download Transactions', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })

        router.push({
          name: 'transactions-download-log',
        })
      })
  }

  const fetchDownloadList = (vm, callback) => {
    store
      .dispatch('merchant/fetchDownloadList')
      .then(response => {
        const { code, data } = response.data

        if (code === '10000') {
          callback(data)
        }
      })
      .catch(() => {
        vm.$bvToast.toast('Error fetching Download List', {
          title: 'Error',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      })
  }

  const fetchTransactionDetail = (params, success, fail) => {
    store
      .dispatch('transaction/fetchTransactionDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  return {
    fetchMerchants,
    fetchMerchantApps,
    fetchChannelFilters,
    fetchPayChannelListDetail,
    searchRecurringOrders,
    downloadTransactions,
    fetchDownloadList,
    fetchTransactionDetail,
  }
}
