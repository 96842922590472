import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // 获取详细
    fetchTransactionDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/detail?app_id=${params.app_id}&out_trade_no=${encodeURIComponent(params.out_trade_no)}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // 退款
    refundConfirm(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/refund-confirm?opt=${params.opt}&out_request_no=${params.out_request_no}&app_id=${params.app_id}&out_trade_no=${params.out_trade_no}&trade_no=${params.trade_no}&reason=${params.reason}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // revokeRefund
    revokeRefund(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/revoke-refund-verifying?out_request_no=${params.out_request_no}&app_id=${params.app_id}&out_trade_no=${params.out_trade_no}&trade_no=${params.trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // callback
    callback(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/callback?app_id=${params.app_id}&out_trade_no=${params.out_trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // fillOrder
    fillOrder(ctx, params) {
      // const config = {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   },
      // }
      return new Promise((resolve, reject) => {
        // axios.post('/transaction/fill-order', params, config)
        axios.post('/transaction/fill-order', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // fetchRecurringOrders
    fetchRecurringOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/recurring/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取退款订单数据
    fetchRefundTransactions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/refund-order/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // cancelOrder
    cancelOrder(ctx, tradeNo) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/cancel?trade_no=${tradeNo}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // recheckOrder
    recheckOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/recheck?app_id=${params.app_id}&out_trade_no=${params.out_trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // renotifyOrder
    renotifyOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/confirm-by-bank?app_id=${params.app_id}&out_trade_no=${params.out_trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    revealCard(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/transaction/reveal-card?app_id=${params.app_id}&out_trade_no=${params.out_trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 获取订单数据
    fetchTransactionsByFilter(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/list-by-filter', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    // 下载订单请求
    downloadTransactionsByFilter(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/download-by-filter', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

  },
}
